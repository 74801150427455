.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(8px); /* For Safari */
}

.overlayContent {
  position: relative;
  min-width: 400px;
}

.closeButton {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: initial;
  border: none;
  padding: 10px;

  &:hover {
    background-color: #00000018;
    cursor: pointer;
  }
}

.crossIcon {
  width: 12px;
  height: 12px;
}

@media (max-width: 600px) {
  .overlayContent {
    min-width: initial;
    max-width: 350px;
  }
}


