@font-face {
  font-family: 'Futura';
  src: url('./fonts/FuturaLT.woff2') format('woff2'),
       url('./fonts/FuturaLT.woff') format('woff');
  font-weight: normal; /* Regular */
}

@font-face {
  font-family: 'Futura';
  src: url('./fonts/FuturaLT-Bold.woff2') format('woff2'),
       url('./fonts/FuturaLT-Bold.woff') format('woff');
  font-weight: bold; /* Regular */
}

@font-face {
  font-family: 'Futura';
  src: url('./fonts/FuturaLT-ExtraBold.woff2') format('woff2'),
       url('./fonts/FuturaLT-ExtraBold.woff') format('woff');
  font-weight: 800; /* Regular */
}

@font-face {
  font-family: 'Futura';
  src: url('./fonts/Futura-Medium.woff') format('woff');
  font-weight: 500; /* Regular */
}

body {
  margin: 0;
  font-family: futura;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.accentBlue {
  color: #30B1E9;
}

.accentOrange {
  color: #FF5733;
}

.underline {
  text-decoration: underline;
}

.boldUnderline {
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.App {
  height: 100vh;
}

.navbar {
  padding: 20px;
  background-color: black;
}

.logo {
  max-width: 150px;
}

header {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tag {
  display: flex;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.058);
  padding: 10px;
  color: rgb(65, 65, 65);
  font-size: 20px;
  align-self: center;
  border: 1px dashed rgba(0, 0, 0, 0.20);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.10);
  text-transform: capitalize;
}

h1 {
  margin: 0;
  font-weight: 800;
  font-size: 40px;
  text-transform: uppercase;
}

h2 {
  margin: 0;
  font-weight: bold;
  font-size: 32px;
}

h3 {
  margin: 0;
}

h4 {
  margin: 0;
}

h5 {
  margin: 0;
  font-weight: 100;
  font-size: 20px;
  color: #0f0f0f;
}

p {
  margin: 0;
  font-size: 20px;
}

button {
  font-size: 20px;
  background-color: #30B1E9;
  color: white;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;

  transition: all 0.5s ease;

  &:hover {
    background-color: #268db9;
    cursor: pointer;
  }

  &.mainCTA {
    align-self: center;
    border: 3px solid rgba(0, 0, 0, 0.455);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.40);
  }
}

input, button {
  font-family: futura;
  border-radius: 8px;
  padding: 13px 23px;
  align-self: stretch;
  border: 1px solid #00000028;
}

input::placeholder {
  color: #B3B3B3;
}

footer {
  position: relative;
  top: -1px;
  background-color: black;
  display: flex;
  padding: 60px;

  img {
    width: 126px;
  }
}

.confirm {
  display: flex;
  align-self: center;
  max-width: 112px;
  max-height: 115px;
}

@media (max-width: 600px) {
  .tag {
    font-size: 16px;
    min-width: 60vw;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 26px;
  }

  h5 {
    font-size: 16px;
  }
}
