.badgeWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  scale: 1;
  background-color: transparent;
  transition: all 0.5 ease;

  &::before {
    content: "";
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: transparent;
    transition: all 0.5 ease;
    z-index: -1;
    border-radius: 10px;
  }

  &:hover {
    scale: 1.05;
    cursor: pointer;

    &::before {
      background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.025) 0%, rgba(0, 0, 0, 0.15) 100%);
      transform: scaleY(1.1) scaleX(0.95);
      clip-path: polygon(
        50% 0%,
        100% 25%,
        100% 75%,
        50% 100%,
        0% 75%,
        0% 25%
      );
      cursor: pointer;
    }
  }
}



.badgeCopyContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.badgeHeader {
  font-size: 13px;
}

.badgeSubheader {
  font-weight: 500;
}