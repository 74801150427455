.wrapper {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 78px - 15vh);
  width: calc(100vw - 40px);
  padding: 5vh 20px 10vh;
  overflow: hidden;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 925px;
  gap: 50px;
  position: relative;
}

@media (max-width: 600px) {
  .container {
    gap: 40px;
  }
}