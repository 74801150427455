.form {
  width: calc(100% - 62px);
  justify-content: center;
  max-width: 390px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #00000028;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
}