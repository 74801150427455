.carouselWrapper {
  display: flex;
  flex-direction: column;
  gap: 38px;
}

.serviceIndicatorsContainer {
  display: flex;
}

.divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.15);
}

.mobileHeaderAndControlsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
}

.chevronButton {
  background-color: white;

  &:hover {
    background-color: rgb(206, 206, 206);
  }
  .chevron {
    height: 16px;
  
    &.reverse {
      transform: rotateY(180deg);
    }
  }
}


.mobileServiceHeader {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: white;

  .header {
    font-size: 18px;
  }

  .subheader {
    font-size: 22px;
  }
}

.carouselContentContainer {
  display: flex;
  padding: 36px;
  border: 5px solid rgba(0, 0, 0, 0.15);
  background-color: #F2F2F2;
  border-radius: 10px;

  &.mobile {
    flex-direction: column;
    padding: 20px;

    ul {
      padding-left: 25px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  li {
    font-size: 20px;
    text-align: left;
  }
}