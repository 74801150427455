.homeBG {
  justify-self: center;
  background-image: url('../../imgs/BG.svg');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.padTop {
  padding-top: 15vh;
}

.beigeBG {
  background-color: #FFFDF9;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.fullWidthBanner {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: black;
  font-size: 28px;
  color: white;
  padding: 66px;
  z-index: 1;

  > * {
    max-width: 928px;
    align-self: center;
  }

  > .bold {
    font-weight: 500;
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 44px;
    gap: 33px;
    font-weight: 500;
  }

  &.gapped {
    gap: 20px;
  }
}

.bold {
  font-weight: bold;
}

.homeSectionWrapper {
  min-height: initial;
  padding: 150px 80px;
  width: calc(100vw - (2 * 80px));
}

.homeSectionContainer {
  flex-direction: row;
  max-width: 1366px;
  gap: 117px;

  > * {
    z-index: 1;
  }

  &.reverse {
    flex-direction: row-reverse;
  }
}

.textSectionContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 750px;
  gap: 33px;
}

.imgContainer {
  position: relative;
}

.sectionImg {
  background-color: white;
  max-width: 500px;
  width: 40%;
  box-shadow: 0px 4px 30.5px 7px rgba(255, 87, 51, 0.25);
  border-radius: 10px;
  border: 1px solid #FF5733;

  &.supported {
    box-shadow: 0px 4px 30.5px 7px rgba(48, 177, 233, 0.25);
    border: 1px solid #30B1E9;
  }
}

.sectionImgBlob {
  position: absolute;
  /* z-index: -1; */
}

.redBlob {
  top: -10vh;
  left: 56vw;
}

.blueBlob {
  bottom: -25vh;
  right: 54vw;
}

.partialBanner {
  display: flex;
  position: relative;
  max-width: calc(75vw - 294px);
  justify-content: flex-end;
  text-align: left;
  color: white;
  padding: 50px 147px;
  background-color: black;
  border-radius: 0 10px 10px 0;
  z-index: 1;

  p {
    max-width: 1366px;
    font-size: 28px;
    line-height: 45px;
    font-weight: 500;
  }
}

.asWritten {
  text-transform: initial;
}

@media (max-width: 1000px) {
  .homeSectionContainer {
    flex-direction: column;

    &.reverse {
      flex-direction: column;
    }
  }

  .sectionImg {
    width: initial;
  }

  .partialBanner {
    max-width: calc(90vw - 294px);

    padding: 50px 100px;

    p {
      max-width: initial;
    }
  }
}


@media (max-width: 600px) {
  .padTop {
    padding-top: 6vh;
  }

  .sectionImg {
    max-width: calc(100% - 40px);
  }

  .homeSectionWrapper {
    min-height: initial;
    align-self: stretch;
    padding: 100px 20px;
    width: calc(100vw - 40px);
  }

  .homeSectionContainer {
    gap: 80px;
  }

  .partialBanner {
    max-width: calc(90vw - 100px);

    padding: 50px 50px;

    p {
      max-width: initial;
    }
  }

  .fullWidthBanner {
    gap: 30px;
    padding-left: 20px;
    padding-right: 20px;

    > div {
      padding-left: 20px;
      padding-right: 20px;
    }

    &.gapped {
      justify-content: center;
      padding: 20px;
      min-height: 80vh;
    }
  }
}